<template>
  <div>
    <BaseButton v-if="!isLoggedIn" type="button" @click="loginDiscord">
      <div class="m-2">Login</div>
    </BaseButton>
    <BaseButton v-if="isLoggedIn" type="button" @click="logout">
      <div class="m-2 hidden md:block">Logout</div>
      <Icon size="1.5rem" class="md:hidden" name="codicon:sign-out" />
    </BaseButton>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";

const userStore = usePocketBase();
const { loginDiscord, logout } = userStore;
const { isLoggedIn } = storeToRefs(userStore);
</script>
